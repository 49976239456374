/* Text styles */
._ClientFeatureList_t01cs_2 ._header_t01cs_2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._ClientFeatureList_t01cs_2 ._header_t01cs_2 ._createButton_t01cs_7 {
  margin-left: auto;
}
._ClientFeatureList_t01cs_2 ._header_t01cs_2 ._bulkApplyButton_t01cs_10 {
  margin-left: 10px;
}
._ClientFeatureList_t01cs_2 ._table_t01cs_13 ._nameCell_t01cs_13 {
  color: #3996e0;
  cursor: pointer;
}