/* Text styles */
._Home_z7wao_2 ._header_z7wao_2 {
  z-index: 2;
  height: 58px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
}
._Home_z7wao_2 ._header_z7wao_2 ._headerContent_z7wao_7 {
  margin: 0 auto;
  height: 100%;
  width: 1280px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
._Home_z7wao_2 ._header_z7wao_2 ._headerContent_z7wao_7 ._logo_z7wao_15 {
  flex-shrink: 0;
  flex-basis: 162px;
  height: 36px;
  background: url(/etc/aspirex/admin/config/esbuild/../../src/app/assets/svgs/logo.svg);
  background-repeat: no-repeat;
  background-size: contain;
}
._Home_z7wao_2 ._header_z7wao_2 ._headerContent_z7wao_7 ._userMenu_z7wao_23 {
  margin-left: auto;
}
._Home_z7wao_2 ._content_z7wao_26 {
  margin: 0 auto;
  padding-top: 40px;
  z-index: 1;
  flex: 1;
  width: 1280px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}
._Home_z7wao_2 ._content_z7wao_26 ._nav_z7wao_36 {
  margin-right: 40px;
  padding-top: 40px;
  flex-shrink: 0;
  flex-basis: 200px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
}
._Home_z7wao_2 ._content_z7wao_26 ._nav_z7wao_36 ._menu_z7wao_46 {
  width: 100%;
}
._Home_z7wao_2 ._content_z7wao_26 ._nav_z7wao_36 ._menu_z7wao_46 ._link_z7wao_49 {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  color: inherit;
}
._Home_z7wao_2 ._content_z7wao_26 ._nav_z7wao_36 ._menu_z7wao_46 ._link_z7wao_49 ._icon_z7wao_56 {
  margin-right: 10px;
}
._Home_z7wao_2 ._content_z7wao_26 ._children_z7wao_59 {
  flex: 1;
  overflow-y: scroll;
}
._Home_z7wao_2 ._content_z7wao_26 ._children_z7wao_59 ._breadcrumb_z7wao_63 {
  margin-bottom: 20px;
}