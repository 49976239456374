._UserMenu_1l557_1 {
  cursor: pointer;
  user-select: none;
  will-change: color;
  transition: color 0.1s ease-out;
}
._UserMenu_1l557_1:hover {
  color: #3996e0;
}
._UserMenu_1l557_1 ._avatar_1l557_10 {
  margin-right: 5px;
}
._UserMenu_1l557_1 ._name_1l557_13 {
  margin-right: 5px;
}

._UserMenuDropdown_1l557_17 ._header_1l557_17,
._SubMenuItem_1l557_18 ._header_1l557_17 {
  pointer-events: none;
}
._UserMenuDropdown_1l557_17 ._header_1l557_17 ._icon_1l557_21,
._SubMenuItem_1l557_18 ._header_1l557_17 ._icon_1l557_21 {
  margin-left: 10px;
  margin-right: 3px;
}
._UserMenuDropdown_1l557_17 ._header_1l557_17 ._clientName_1l557_26,
._SubMenuItem_1l557_18 ._header_1l557_17 ._clientName_1l557_26 {
  color: #3996e0;
  font-weight: bold;
}
._UserMenuDropdown_1l557_17 ._icon_1l557_21,
._SubMenuItem_1l557_18 ._icon_1l557_21 {
  margin-right: 8px;
}