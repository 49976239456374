/* Text styles */
._ReportingListView_1i8f9_2 ._header_1i8f9_2 {
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
._ReportingListView_1i8f9_2 ._header_1i8f9_2 ._addButton_1i8f9_8 {
  margin-left: auto;
}
._ReportingListView_1i8f9_2 ._table_1i8f9_11 ._nameCell_1i8f9_11 {
  color: #3996e0;
  cursor: pointer;
}

._AddOrEditModal_1i8f9_16 ._select_1i8f9_16 {
  width: 100%;
}
._AddOrEditModal_1i8f9_16 ._input_1i8f9_19 {
  width: 100%;
  margin-bottom: 10px;
}
._AddOrEditModal_1i8f9_16 ._inputLabel_1i8f9_23 {
  font-weight: bold !important;
}