._html_bexs5_1 {
  position: relative;
  box-sizing: border-box;
  color: #1a1818;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 14px;
  line-height: 20px;
}
._html_bexs5_1 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._html_bexs5_1 ::-webkit-scrollbar-track, ._html_bexs5_1 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._html_bexs5_1 ::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-color: #c6c6c6;
}

/* Text styles */
._html_bexs5_1 {
  font-size: initial;
  height: 100%;
}
._html_bexs5_1 ._body_bexs5_27 {
  margin: 0;
  height: 100%;
  background-color: #fdfdfd;
}
._html_bexs5_1 ._body_bexs5_27 ._content_bexs5_32 {
  height: 100%;
}

*, :after, :before {
  box-sizing: border-box;
}