._LoginView_lwb21_1 {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
._LoginView_lwb21_1 ._card_lwb21_8 {
  padding: 60px 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fdfdfd;
  border: 1px solid #dadcde;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
}
._LoginView_lwb21_1 ._logo_lwb21_19 {
  margin-bottom: 30px;
  width: 250px;
  height: 100px;
  background: url(/etc/aspirex/admin/config/esbuild/../../src/app/assets/svgs/logo.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
._LoginView_lwb21_1 ._loginButton_lwb21_28 {
  margin-bottom: 40px;
}
._LoginView_lwb21_1 ._errorMessage_lwb21_31 {
  color: red;
}